<template>
<v-row>
    <v-col lg="12" cols=12 style="padding-bottom:0px;">
        <s-crud height="180" title="Circuitos de Aprobacion" :config="config" :add="isCrud" :edit="isCrud" ref="gridAcc" :remove="isCrud" :filter="filter" @save="saveAcc($event)" @rowSelected="rowSelected" searchInput :singleRow="singleRow">
            <template slot="filter">
                <v-container>
                    <v-row class="justify-center">
                        <v-switch label="Circuitos activos" v-model="filter.SecStatus" dense hide-details :false-value="0" :true-value="1" />
                    </v-row>
                </v-container>
            </template>
            <template v-if="isCrud" v-slot:default="{ item }">
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12" lg="12" md="12" class="s-col-form">
                            <s-text v-model="item.AccName" label="Nombre de Circuito" autofocus />
                        </v-col>

                    </v-row>
                    <v-row justify="center">
                        <v-col cols="6" lg="6" class="s-col-form">
                            <s-select-transaction v-model="item.TypeCircuit" label="Tipo Circuito" :typeprocess="1" />
                        </v-col>
                        <v-col cols="6" lg="6" class="s-col-form">
                            <c-select-area full clearable noDefault v-model="item.AreID" label="Área" />
                            <span style="font-size: 10px; color:red"><b>Para Circuito Aprobación de Orden de Compra, se debe configurar por Área Superior</b></span> 
                        </v-col>

                        <!-- <v-col lg="4" md="4" cols="12" class="s-col-form">
                            <s-select-definition v-model="item.TypeArea" :def="1147" label="Tipo de Área"> </s-select-definition>
                        </v-col>
                        <v-col lg="4" md="4" cols="12" class="s-col-form">
                            <c-select-area full clearable :typeArea="item.TypeArea == 1 ? 3 : item.TypeArea - 1" v-model="item.AreID" label="Área Superior">
                            </c-select-area>
                        </v-col> -->

                        <v-col cols="6" lg="6" class="s-col-form">
                            <s-date label="Fecha Inicio" v-model="item.AccBeginDate" />
                        </v-col>
                        <v-col cols="6" lg="6" class="s-col-form">
                            <s-date clearable label="Fecha Fin" v-model="item.AccEndDate" />
                        </v-col>

                        <!--  <v-col cols="1" class="s-col-form">
                  <s-switch class="mt-2" label="Activo" v-model="item.SecStatus" />
                </v-col>-->
                    </v-row>
                </v-container>
            </template>
            <template v-slot:SecStatus="{ row }">
                <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'">
                    mdi-checkbox-blank-circle</v-icon>
            </template>
        </s-crud>
        <v-divider></v-divider>
    </v-col>
    <v-col lg="12">
        <s-autorize-detail :circuit=circuit v-if="parentID != 0" :parentID="parentID" />
    </v-col>
</v-row>
</template>

<script>
import _sApprovalCircuit from "@/services/Security/ApprovalCircuitService";
import sAutorizeDetail from "./AutorizeDetail";
export default {
    components: {
        sAutorizeDetail
    },
    props: {
        singleRow: {
            type: Boolean,
            default: true
        },
        isCrud: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            acc: [],
            filter: {
                SecStatus: 1,
                TypeProccess: 1
            },
            parentID: 0,
        };
    },
    computed: {
        config() {
            return {
                model: {
                    circuit: null,
                    AccName: "string",
                    AccID: "ID",
                    AccBeginDate: "date",
                    AccEndDate: "date",
                    TypeCircuitText: "string",
                    AreName: "string",
                    SecStatus: "status",
                },
                service: _sApprovalCircuit,
                headers: this.isCrud ? [{
                        text: "ID",
                        value: "AccID",
                        align: "end",
                    },
                    {
                        text: "Circuito",
                        value: "AccName"
                    },
                    {
                        text: "Tipo Circuito",
                        value: "TypeCircuitText"
                    },
                    {
                        text: "Area",
                        value: "AreName"
                    },
                    {
                        text: "Fecha Inicio",
                        value: "AccBeginDate",
                    },
                    {
                        text: "Fecha Fin",
                        value: "AccEndDate",
                    },
                    {
                        width: "5%",
                        text: "Sec",
                        value: "SecStatus",
                        sortable: false,
                    },
                ] : [{
                        text: "ID",
                        value: "AccID",
                        align: "end",
                    },
                    {
                        text: "Circuito",
                        value: "AccName"
                    },
                ],
            };
        },
    },
    methods: {
        rowSelected(rows) {
            if (rows.length > 0) {
                this.circuit = rows[0];
                this.parentID = rows[0].AccID;
            } else {
                this.parentID = 0
            }
        },

        validatedCircuit(item) {
            let isValid = false;
            let message = "";

            isValid = item.AccName.length > 0;
            if (!isValid) message = "Ingrese Nombre de Circuito";
            else {
                isValid = item.AccBeginDate;
                if (!isValid) message = "Ingrese Fecha de Inicio Circuito";
                else {}
            }

            if (!isValid) this.$fun.alert(message, "warning");

            return isValid;
        },
        saveAcc(item) {

            item.TypeProccess = 1 //Circuitos de Logistica

            if (item.TypeCircuit == null) {
                this.$fun.alert("Seleccione Circuito", "warning")
                return
            }

            if (item.AreID == null) {
                this.$fun.alert("Seleccione Área", "warning")
                return
            }

            console.log("saveee logistica", item)
            if (this.validatedCircuit(item)) {
                item.save();
            }
        },
    },
};
</script>
